$( function() {
  // smooth scroll
  $('a[href^=#]').click(function(){
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top -120;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });

  // お問合せ用
  if($('.form-item').length) {
    if($('span.error').length) {
      $('span.error').each(function() {
        $(this).prev('input, textarea').addClass('is-error');
      });
    }
  }
  if($('.mw_wp_form_input .form-en').length) {
      if( $('span.error').length) {
        $('span.error').each(function() {
          if($(this).text() === '未入力です。') $(this).text('Required information missing.');
        });
      }
      var msg = 'Please put "@" in the mail address';
      var email = document.getElementById("input_mail");
      email.addEventListener("input", function (event) {
        if (email.validity.typeMismatch) {
          email.setCustomValidity(msg);
        } else {
          email.setCustomValidity("");
        }
      });
      $('.form-cont.form-en span.error').html('There is an error.');
  }

  if($('.btn input[type="submit"]').length) {
    $('.btn input[type="submit"]').prev('span').text($('.btn input[type="submit"]').val());
  }

  // SPグロナビ
  $('.js-navTrigger').on('click', function() {
    $('.header-sp-nav').slideToggle();
    $('.l-header').toggleClass('is-open');
    return false;
  });

  // ラインナップ
   $('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.slider-nav'
  });
  $('.slider-nav').slick({
    asNavFor: '.slider-for',
    arrows: false,
    dots: true,
    centerMode: false,
    focusOnSelect: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    dots: false,
    infinite: false,
    swipe: false,
    swipeToSlide: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          swipe: true,
          swipeToSlide: true,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '100px',
          dots: true,
          infinite: true,
        },
      },
    ]
  });


  if($('.top-hero').length) {
    // メイン動画
    var getWindowMovieHeight = function() {
      // ここでブラウザの縦横のサイズを取得します。
      var windowSizeHeight = $(window).outerHeight();
      var windowSizeWidth = $(window).outerWidth();

      // メディアの縦横比に合わせて数値は変更して下さい。(メディアのサイズが width < heightの場合で書いています。逆の場合は演算子を逆にしてください。)
      var windowMovieSizeWidth = windowSizeHeight * 1.76388889;
      var windowMovieSizeHeight = windowSizeWidth / 1.76388889;
      var windowMovieSizeWidthLeftMargin = (windowMovieSizeWidth - windowSizeWidth) / 2;

      if (windowMovieSizeHeight < windowSizeHeight) {
        // 横幅のほうが大きくなってしまう場合にだけ反応するようにしています。
        $(".top-hero video").css({left: -windowMovieSizeWidthLeftMargin});
      }
    };

    // 以下画面の可変にも対応できるように。
    $(window).on('load', function(){
      getWindowMovieHeight();
    });

    $(window).on('resize', function(){
      getWindowMovieHeight();
    });

    // フェードイン
    $(window).on('load', function(){

      // テキストアニメーション
      var $allMsg = $('.top-hero h1 p').eq(0);
      var $wordList = $('.top-hero h1 p').eq(0).html().replace(/ /g, '★').split("");
      $('.top-hero h1 p').eq(0).html("").show();
      $.each($wordList, function(idx, elem) {
          if(elem == '★') {
            elem = '';
            var newEL = $("<span style='width: 1.5rem' />").text(elem);
          } else {
            var newEL = $("<span />").text(elem);
          }
          newEL.appendTo($allMsg);
          setTimeout(function() {
            newEL.addClass('is-show');
          },idx * 150);
      });

      var cnt = $wordList.length;
      var $allMsg = $('.top-hero h1 p').eq(1);
      var $wordList = $('.top-hero h1 p').eq(1).html().replace(/ /g, '★').split("");
      $('.top-hero h1 p').eq(1).html("").show();
      $.each($wordList, function(idx, elem) {
          if(elem == '★') {
            elem = '';
            var newEL = $("<span style='width: 1.5rem' />").text(elem);
          } else {
            var newEL = $("<span />").text(elem);
          }
          newEL.appendTo($allMsg);
          setTimeout(function() {
            newEL.addClass('is-show');
          },(cnt+1+idx) * 150);
      });

      $('.top-hero-inner > p.sp-hide span').each( function(i) {
        setTimeout(function() {
          $('.top-hero-inner > p.sp-hide span').eq(i).addClass('is-show');
        },i * 800 + 3500);
      });
      setTimeout(function() {
        $('.top-hero-inner > p.pc-hide span').addClass('is-show');
      },3500);


      // $('.top-hero').addClass('is-show');

    });    
  }


  $(window).on('scroll', function() {
    var st = $(window).scrollTop();
    if(st >= $(window).height() * .5) {
      $('.top-concept h1').addClass('is-show');
      $('.top-concept p').each( function(i) {
        setTimeout(function() {
          $('.top-concept p').eq(i).addClass('is-show');
        },i * 1000 + 1000);
      });
    }

  })

});